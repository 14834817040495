import { ComponentProps, Fragment } from "react";
import { ChangeButton, Drawer } from "../../components";
import BrandCard from "./BrandCard";
import ProductGroupCard from "./ProductGroupCard";
import ProgramCard from "./ProgramCard";
import SupplierCard from "./SupplierCard";

type Props = {
  supplier: ComponentProps<typeof SupplierCard>["supplier"];
  program: ComponentProps<typeof ProgramCard>["program"];
  onChange?: VoidFunction;
};

export default function LineItemHeaderInfo({
  supplier,
  program,
  onChange,
}: Props) {
  const hasSupplier = !!supplier;
  const hasProductGroup = !!program?.pimProductGroup;
  const className = "flex flex-center text-start border-b-0 gap-2";
  return (
    <Fragment>
      {hasSupplier && (
        <Drawer.Header className={className}>
          <SupplierCard supplier={supplier} className="flex-1" />
          <ProgramCard program={program} className="flex-1" />
          <ChangeButton onClick={onChange} />
        </Drawer.Header>
      )}
      {hasProductGroup && (
        <Drawer.Header className={className}>
          <ProductGroupCard
            productGroup={program?.pimProductGroup ?? null}
            className="flex-1"
          />
          <BrandCard brand={program?.brand ?? null} className="flex-1" />
          <ChangeButton
            onClick={onChange}
            className="pointer-events-none opacity-0"
          />
        </Drawer.Header>
      )}
    </Fragment>
  );
}
