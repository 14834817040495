import { Dispatch } from "react";
import { Button, Icon, Text } from "../../../components";
import { useSelector } from "../../../hooks";

type Props = {
  value: string | null;
  setValue: Dispatch<string | null>;
  disabled: boolean;
};

export default function SaleChannelFilter({
  value,
  setValue,
  disabled,
}: Props) {
  const salesChannels = useSelector((s) => s.salesChannels);
  const items = [
    { name: "button.allOrders", id: null },
    ...salesChannels.map((e) => ({ name: e.title, id: e.code })),
  ];
  const handleClick = (id: string | null) => {
    return () => {
      const active = id === value;
      !active && setValue(id);
    };
  };
  return (
    <section className="flex flex-wrap items-center gap-x-3 gap-y-1">
      {items.map((e) => {
        const active = e.id === value;
        return (
          <Button
            key={e.name}
            type="button"
            variant={active ? "primary" : "white"}
            onClick={handleClick(e.id)}
            disabled={disabled}
            className="flex items-center gap-1 text-sm font-medium px-2.5 py-1.5"
          >
            <Icon name="DirectboxDefault" variant="Bold" className="size-3.5" />{" "}
            <Text>{e.name}</Text>
          </Button>
        );
      })}
    </section>
  );
}
