const errorCodes = {
  default: "Es ist ein Fehler aufgetreten",
  file: "Bitte Datei auswählen",
  form: "Die Daten sind nicht korrekt",
  server: "Serverfehler",
  incidentCustomerMood: "Kundenstimmung ist erforderlich",
  inValidAddress: "Adresse ist ungültig.",
  lineItemInventory: "Dieser Inventarservice ist zurzeit nicht verfügbar",
  noSecondSalesPersonAcceptOffer:
    "Keine Person mit dieser Nummer gefunden, bitte erneut versuchen",
  A1002: "Benutzer nicht gefunden",
  A1003: "Mitarbeiter nicht gefunden",
  A1004: "Benutzergruppe nicht gefunden",
  A1005: "Rechtseinheit nicht gefunden",
  A1007: "Kennen wir schon: Handynummer ist bereits registriert",
  A1008: "Kundenkonto nicht gefunden",
  A1009: "Verifizierungscode für Handynummer ist ungültig",
  A1010: "Handynummer ist noch nicht verifiziert",
  A1011: "E-Mail-Adresse ist nicht verifiziert",
  A1012: "Passcode muss festgelegt werden",
  A1013: "E-Mail-Adresse ist bereits registriert",
  A1014: "Passcode muss gültig sein",
  A1015: "E-Mail-Adresse stimmt nicht überein",
  A1016: "Rechtseinheit mit demselben Code existiert bereits",
  A1017: "Neuer Passcode stimmt nicht überein",
  A1018: "Ungültiger Passcode",
  A1019: "Nutzername stimmt nicht mit der E-Mail-Adresse überein",
  A1020: "Benutzer hat sich bereits angemeldet",
  A1021: "Benutzer existiert bereits",
  A1022: "Gerätetoken ist ungültig",
  A1023: "E-Mail-Bestätigungscode ist abgelaufen",
  A1024: "E-Mail-Bestätigungscode ist ungültig",
  A1025: "Handynummer ist nicht bestätigt",
  A1026: "Kunde kann nicht entfernt werden",
  A1027: "Kunde muss den DSGVO-Bedingungen zustimmen",
  A1028: "Kunde muss eine Person sein",
  A1029: "Handynummer muss registriert sein",
  A1030: "Steuernummer des Unternehmens muss ausgefüllt werden",
  A1031: "Unternehmensregistrierungsnummer muss einzigartig sein",
  A1032: "Kunde muss ein Unternehmen sein",
  A1033: "Ansprechpartner des Unternehmens nicht gefunden",
  A1034: "Land nicht gefunden",
  A1035: "Kundenadresse nicht gefunden",
  AS1001: "ELO-Konfiguration nicht gefunden",
  AS1002: "ELO-Konfiguration existiert bereits",
  AS1003: "ELO-Dienst nicht verfügbar",
  AS2001: "Persönliche Azure-Blob-Konfiguration nicht gefunden",
  AS3001: "Mandanten-Speicherkonfiguration nicht gefunden",
  AS3002: "Speichertyp für PULS-Dateityp nicht festgelegt",
  PE0000: "Berechnungseinheit nicht gefunden",
  PE0100: "Angepasste Berechnungseinheit nicht gefunden",
  PE0101: "Benutzerdefinierte Berechnungseinheit nicht gefunden",
  PE0200: "Preisvorlagen-Code existiert bereits",
  PE0201: "Einkaufspreisvorlage nicht gefunden",
  PE0202: "Einkaufspreisvorlage ist nicht aktiv",
  PE0203: "Zeitraum der Einkaufspreisvorlage hat noch nicht begonnen",
  PE0204: "Zeitraum der Einkaufspreisvorlage ist beendet",
  PE0205: "Keine Einkaufspreisvorlage verfügbar",
  PE0206: "Einkaufspreisvorlage enthält keine Artikel",
  PE0300: "Verkaufspreisvorlage nicht gefunden",
  PE0301: "Verkaufspreisvorlage ist nicht aktiv",
  PE0302: "Zeitraum der Verkaufspreisvorlage hat noch nicht begonnen",
  PE0303: "Zeitraum der Verkaufspreisvorlage ist beendet",
  PE0304: "Keine Verkaufspreisvorlage verfügbar",
  PE0305: "Verkaufspreisvorlage enthält keine Artikel",
  PE0306: "Optionale Rabatte in Verkaufspreisvorlage nicht gefunden",
  PE0400: "Artikel der Einkaufspreisvorlage nicht gefunden",
  PE0500: "Artikel der Verkaufspreisvorlage nicht gefunden",
  PE0600: "Werteingabe Manueller Rabatt erforderlich",
  PE0601: "Rabatt liegt außerhalb des definerten Bereichs",
  12000: "Doppelte Iwofurn-Konfiguration",
  12001: "Iwofurn-Konfiguration nicht gefunden",
  12002: "Store-ILN existiert nicht",
  12003: "Iwofurn-Konfigurations-URL nicht festgelegt",
  12004: "Iwofurn-Konfigurations-Benutzername nicht festgelegt",
  12005: "Iwofurn-Konfigurations-Passwort nicht festgelegt",
  12006: "Interner Iwofurn-Tag nicht gefunden",
  12007: "Iwofurn-Bestellung enthält keine Positionen",
  12008: "Iwofurn-API antwortet nicht",
  SS0001: "Objekt nicht gefunden",
  SS0002: "Verkaufskanal nicht gefunden",
  SS0003: "Verkaufskanal-Code muss einzigartig sein",
  SS0100: "Kanalnachricht nicht gefunden",
  SS0200: "PIM-Produkt nicht gefunden",
  SS4000: "Lieferant nicht gefunden",
  SS5006: "Anzahlungsgrund kann nicht leer oder null sein",
  SS6002: "Programm nicht gefunden",
  SS7024: "PIM-Produktlieferant ist nicht festgelegt",
  SS7025: "PIM-Produktlieferantenprogramm ist nicht festgelegt",
  SS7032: "PIM Verfügbarkeitsstatus nicht gefunden",
  SS7033: "PIM Verfühbarkeitsstatus wurde geerbt",
  SS7034: "PIM Persiode des Status beachten!",
  SS8025: "Angebot nicht gefunden",
  SS8026: "Dokument nicht gefunden",
  SS8027: "Bestellung nicht gefunden",
  SS8028:
    "Bestellung kann nicht erstellt werden, wenn die Lieferant-ID der Position leer ist",
  SS8029:
    "Bestellung kann nicht erstellt werden, wenn die Unterlieferant-ID der Position leer ist",
  SS8030: "Angebotsposition nicht gefunden",
  SS8031: "Angebotsdokument nicht gefunden",
  SS8032: "Angebot ist abgelaufen",
  SS8033: "Kunde nicht gefunden",
  SS8034: "Ansprechpartner des Kundenunternehmens nicht gefunden",
  SS8035: "Kunde hat keine CIM-Nummer",
  SS8036: "Kunde ist nicht genehmigt",
  SS8037: "Bestellposition nicht gefunden",
  SS8038: "Angebotsposition ist im Inventar reserviert",
  SS8039: "Angebotsposition ist im Inventar nicht reserviert",
  SS8040: "Alle Angebotspositionen müssen eine Versandmethode haben",
  SS8041:
    "Bestätigungstermin des Kunden kann nicht in der Vergangenheit liegen",
  SS8042: "Bestellposition nicht gefunden",
  SS8043: "Bestellposition ist im Inventar reserviert",
  SS8045: "Bestellposition ist im Inventar nicht reserviert",
  SS8046: "Bestelldokument PDF wurde nicht erstellt",
  SS8047: "Bestelldokument nicht gefunden",
  SS8048:
    "Angebotsposition ist bereits mit der angegebenen Menge im Inventar reserviert",
  SS8049: "Gewünschtes Lieferdatum des Angebots ist ungültig",
  SS8050: "Gewünschtes Lieferdatum der Bestellung ist ungültig",
  SS8051: "Gewünschtes Lieferdatum der Angebotsposition ist ungültig",
  SS8052: "Gewünschtes Lieferdatum der Bestellposition ist ungültig",
  SS8053: "Bündelposition nicht gefunden",
  SS8060:
    "Das Verfügbarkeitsstatusprogramm des Lieferanten für Angebotspositionen ist nicht gültig",
  SS8061:
    "Das Verfügbarkeitsstatusprogramm des Lieferanten für Bestellpositionen ist nicht gültig",
  SS8064:
    "Das Produktverfügbarkeitsstatus der Angebotsposition im PIM ist nicht gültig",
  SS8065:
    "Das Produktverfügbarkeitsstatus der Bestellposition im PIM ist nicht gültig",
  SS8068: "Apply optional discount is not allowed DE",
  SS8069: "Apply individual discount is not allowed DE",
  SS14208: "Preismotor-Service ist nicht verfügbar",
  SS15003: "Excel-Datei enthält keine Daten",
  SS14100: "Dokumentvorlage nicht gefunden",
  SS14101: "Angebots-PDF-Dokument wurde nicht erstellt",
  SS14103: "Angebot wurde akzeptiert",
  SS14104: "Angebot ist nicht aktiv",
  SS14105: "Standard-Dokumentvorlage nicht gefunden",
  SS14106: "Dokumentvorlage existiert bereits",
  SS14107: "Der MIME-Typ der Dokumentvorlage muss übereinstimmen",
  SS14300: "ERP-Inventarkonfiguration nicht gefunden",
  SS14301: "ERP-Inventardienst-Anmeldung bei Microsoft fehlgeschlagen",
  SS14302: "ERP-Inventardienst-Anmeldung bei Dynamics fehlgeschlagen",
  SS14303: "ERP-Inventardienst ist nicht verfügbar",
  SS14304:
    "Nur Filialen mit Rechtsträgercode können im ERP-Inventardienst verwendet werden",
  SS14305: "ERP-Inventardienst: Reservierung des Produkts nicht verfügbar",
  SS14306:
    "ERP-Inventardienst: Reservierung des Produkts im Lager fehlgeschlagen",
  SS14307:
    "ERP-Inventardienst: Aufhebung der Reservierung des Produkts nicht verfügbar",
  SS14308:
    "ERP-Inventardienst: Aufhebung der Reservierung des Produkts im Lager fehlgeschlagen",
  SS14309: "ERP-Inventardienst: Produktmenge reicht nicht aus",
  SS14310:
    "Nur Produkte mit Artikelnummer können im ERP-Inventardienst verwendet werden",
  SS14311: "ERP-Inventardienst: Keine Antwort für Lagerbestandsabfrage",
  SS14312:
    "ERP-Inventardienst: Standort für Lagerbestandsabfrage existiert nicht",
  SS14313:
    "ERP-Inventardienst: Microsoft-Anmeldung führte zu einer fehlerhaften Anforderung",
  SS14314:
    "ERP-Inventardienst: Microsoft-Anmeldung führte zu einem unbefugten Ergebnis",
  SS14315:
    "ERP-Inventardienst: Dynamics-Anmeldung führte zu einer fehlerhaften Anforderung",
  SS14316:
    "ERP-Inventardienst: Dynamics-Anmeldung führte zu einem unbefugten Ergebnis",
  SS14400: "Verkäuferinformationen nicht gefunden",
  SS15000: "Datei nicht gefunden",
  SS16000: "Auftragsgruppe für Provisionen darf nicht leer sein",
  SS16001:
    "Aufträge von verschiedenen Kunden können nicht zur gleichen Auftragsgruppe für Provisionen hinzugefügt werden",
  SS16002:
    "Aufträge von verschiedenen Filialen können nicht zur gleichen Auftragsgruppe für Provisionen hinzugefügt werden",
  SS16003:
    "Aufträge mit bearbeitetem Status können nicht zur Auftragsgruppe für Provisionen hinzugefügt werden",
  SS16004:
    "Aufträge mit einem späteren Lieferdatum können nicht zur Auftragsgruppe für Provisionen hinzugefügt werden",
  SS16005:
    "Lieferdatum der Auftragsgruppe für Provisionen darf nicht in der Vergangenheit liegen",
  SS16006: "Auftragsgruppe für Provisionen nicht gefunden",
  SS16007:
    "Der Auftrag ist bereits einer Auftragsgruppe für Provisionen zugeordnet",
  SS16008: "Auftrag wurde noch nicht veröffentlicht",
  SS17200: "Druckanforderung wurde nicht gefunden",
  SS17201: "Druckanforderung m,eldet keinen Status",
  SS18019: "Nur die letzet Phsa kann geschlossen werden.",
  SS18027:
    "Startphase kann nur nach kompletter Bearbeitung der Checkliste beginnen",
  SS18010:
    "Der Phasenwechsel ist ersta nach Abschluss der aktuellen Phase möglich.",
  SS0500: "Lieferantenprogramm nicht gefunden",
  SS0501: "Verfügbarkeitsstatus des Lieferantenprogramms nicht gefunden",
  SS0502: "Verfügbarkeitsstatus des Lieferantenprogramms ist Standard",
  SS0503:
    "Der Datumsbereich des Verfügbarkeitsstatus des Lieferantenprogramms sollte nicht in Konflikt stehen",
  SS0504: "Lieferantenurlaub nicht gefunden",
  SS0505: "Lieferantenkontaktperson nicht gefunden",
  SS6100: "Filiale nicht gefunden",
  SS8054: "Unterpositionen werden nur zu Bündelprodukten hinzugefügt",
  SS8055: "Unterpositionen eines Bündels können nicht storniert werden",
  SS8056: "Veröffentlichte Bestellpositionen können nicht gelöscht werden",
  SS8062: "Ungültiger Angebotspositionstyp",
  SS8063: "Ungültiger Bestellpositionstyp",
  C9001: "Konfiguration nicht gefunden",
  C9002: "Senden der Nachricht an ERP fehlgeschlagen",
  C9003: "Kanalnachricht nicht gefunden",
  C9004: "Favorit nicht gefunden",
  C9005: "Favoriteneintrag muss eindeutig sein",
  C9006: "Favoriteneintrag nicht gefunden",
  C9007: "Vorfall kann nicht erstellt werden",
  C9008: "Vorfall kann nicht aktualisiert werden",
  C9009: "Bestellung kann im CRM nicht aktualisiert werden",
  C9101: "CRM-Store nicht gefunden",
  C9102: "CRM-Bestellung nicht gefunden",
  C9103: "CRM-Bestellungsdetails nicht gefunden",
  C9104: "CRM-Kunde nicht gefunden",
  C9105: "CRM-Mitarbeiter nicht gefunden",
  C9106: "Mitarbeiter kann im CRM nicht aktualisiert werden",
  C9107: "Verkaufswerkzeug PIM-Produkt nicht gefunden",
  C9108: "PIM-Produktattribute müssen eindeutig sein",
  C9109: "Bestellprovisionsgruppe kann nicht entfernt werden",
  C9110: "Bestellprovisionsgruppe kann nicht geändert werden",
  C9111: "Kunde kann im CRM nicht aktualisiert werden",
  C9112: "Kontaktperson kann nicht deaktiviert werden",
  C9113: "CRM-Kundentyp nicht definiert",
  C9114: "Kontaktperson kann im CRM nicht aktualisiert werden",
  C9115: "Keine Preisliste im CRM vorhanden",
  C9116: "Keine Transaktionswährung im CRM vorhanden",
  C9117: "Ausgewählte Währung im CRM nicht gefunden",
  C9118: "Preisliste kann nicht aus dem CRM abgerufen werden",
  C9119: "Transaktionswährungsliste kann nicht aus dem CRM abgerufen werden",
  C9120: "CRM-Kundennummer (CIM) ist leer",
  C9121: "CRM-Rechtsentitätscode ist leer",
  C9122: "CRM-Mitarbeiternummer ist leer",
  C9123: "Kunde mit eingegebener CIM-Nummer im CRM nicht gefunden",
  C9124: "Store mit eingegebenem Rechtsentitätscode im CRM nicht gefunden",
  C9125: "Kunde mit CIM-Nummer kann nicht aus dem CRM abgerufen werden",
  C9126: "Store mit Rechtsentitätscode kann nicht aus dem CRM abgerufen werden",
  C9127:
    "Mitarbeiter mit Mitarbeitennummer kann nicht aus dem CRM abgerufen werden",
  C9128: "CRM-Kundenmodell ist leer",
  C9129: "CRM-Verkäufermodell ist leer",
  C9130: "Keine Währung im CRM vorhanden",
  C9131: "Währungsliste kann nicht aus dem CRM abgerufen werden",
  C9132: "Synchronisation der Bestelldetails mit dem CRM fehlgeschlagen",
  C9133: "Synchronisation der Bestelldokumente mit dem CRM fehlgeschlagen",
  C9134: "Lieferant mit eingegebener Lieferantennummer im CRM nicht gefunden",
  C9135:
    "Lieferant mit Lieferantennummer kann nicht aus dem CRM abgerufen werden",
  C9136: "Abruf der Bestelldetails aus dem CRM fehlgeschlagen",
  C9137: "Abruf der Bestelldokumente aus dem CRM fehlgeschlagen",
  C9138: "Änderung des Bestellstatus im CRM fehlgeschlagen",
  C9139: "Änderung des Bestellstatus im CRM fehlgeschlagen",
  C9140: "Synchronisation des Lieferanten mit dem CRM fehlgeschlagen",
  C9141: "Synchronisation der Rechtsentität mit dem CRM fehlgeschlagen",
  C9142: "Synchronisation des Stores mit dem CRM fehlgeschlagen",
  C9143: "Änderung der Bestellprovision im CRM fehlgeschlagen",
  C91044: "CRM-Lieferant nicht gefunden",
  C9145: "Synchronisation der Kontaktperson mit dem CRM fehlgeschlagen",
  C9146: "Synchronisation des Kundenkontos mit dem CRM fehlgeschlagen",
  C9147: "Synchronisation der Favoriten mit dem CRM fehlgeschlagen",
  C9148: "Synchronisation des Lieferantenurlaubs mit dem CRM fehlgeschlagen",
  C9149: "Synchronisation des Lieferantenprogramms mit dem CRM fehlgeschlagen",
  C9150: "Entfernen von Favoriten aus dem CRM fehlgeschlagen",
  C9151: "Entfernen der Bestellprovisionsgruppe aus dem CRM fehlgeschlagen",
  C9152: "Synchronisation der Aufgabe mit dem CRM fehlgeschlagen",
  C9153: "Zuweisung der Aufgabe im CRM fehlgeschlagen",
  C9154: "Änderung der Aufgabe im CRM fehlgeschlagen",
  C9155: "Abruf von Leads aus dem CRM fehlgeschlagen",
  C9156: "Abruf der Aufgabe aus dem CRM fehlgeschlagen",
  C9157: "Abruf der Aufgabenliste aus dem CRM fehlgeschlagen",
  C9158: "Abruf der Aktivität aus dem CRM fehlgeschlagen",
  C9159: "Änderung des Vorfallstatus im CRM fehlgeschlagen",
  C9160: "Zuweisung des Vorfalls im CRM fehlgeschlagen",
  C9161: "Abruf der Vorfallliste aus dem CRM fehlgeschlagen",
  C9162: "Abruf des Vorfalls aus dem CRM fehlgeschlagen",
  C9900: "Unbekannter CRM-Fehler",
  C9901: "CRM-Dataverse-Verbindung fehlgeschlagen",
  C9163: "CRM-Abteilung nicht gefunden",
  C9164: "Synchronisation der Abteilung mit dem CRM fehlgeschlagen",
  C9165: "Deaktivierung der Abteilung im CRM fehlgeschlagen",
  C9166: "Erstellung der Abteilung im CRM fehlgeschlagen",
  C9167: "Löschen der Abteilung im CRM fehlgeschlagen",
  C9168: "Abruf des Vorfallbetreffs aus dem CRM fehlgeschlagen",
  C9169: "CRM-Betreff nicht gefunden",
  C9170: "CRM-Lieferantencode ist leer",
  C9171: "Mitarbeiter mit eingegebener Mitarbeiternummer im CRM nicht gefunden",
  C9172: "CRM-Lieferantenprogramm nicht gefunden",
  C9173: "Abruf der Notizliste aus dem CRM fehlgeschlagen",
  C9174: "Erstellung der Notiz fehlgeschlagen",
  C9175: "Abruf der Timeline-Daten fehlgeschlagen",
  C9176: "Erstellung der Vorfall-Dateien fehlgeschlagen",
  C9177: "Abruf der Vorfall-Dateien fehlgeschlagen",
  C9178: "Synchronisation des Verkaufskanals mit dem CRM fehlgeschlagen",
  C9179: "Verkaufskanal nicht gefunden",
  C9180: "Datei nicht gefunden",
  C9181:
    "Aufhebung der Veröffentlichung der Kontaktperson im CRM fehlgeschlagen",
  C9182: "Festlegen der aktiven Phase fehlgeschlagen",
  C9183: "Abruf der Vorfall-Phasenliste fehlgeschlagen",
  C9184: "Bestellfeld ist erforderlich",
  C9185: "Feld für technische Inspektion ist erforderlich",
};
export default errorCodes;
