import {
  AvailabilityStatus,
  CRMActivityStatus,
  CRMTaskStatusReason,
  CompanyApprovalStatus,
  CustomerStatus,
  DocumentStatus,
  GeneratePdfRequestStatus,
  IncidentStatus,
  IncidentStatusReason,
  OfferStatus,
  OrderInternalStatus,
  PickupStatus,
  PurchaseStatus,
  SalesStatus,
  SellOffProductStatus,
} from "../enums";
import { iconNameType, variantType } from "../types";

type StatusItem = { name: string; id: number; variant: variantType };
const customer: StatusItem[] = [
  {
    name: "status.customer.onboarding",
    id: CustomerStatus.Onboarding,
    variant: "primary",
  },
  {
    name: "status.customer.registered",
    id: CustomerStatus.Registered,
    variant: "success",
  },
];
const company: StatusItem[] = [
  {
    name: "status.company.approved",
    id: CompanyApprovalStatus.Approved,
    variant: "success",
  },
  {
    name: "status.company.pending",
    id: CompanyApprovalStatus.PendingApproval,
    variant: "warning",
  },
  {
    name: "status.company.suspended",
    id: CompanyApprovalStatus.Suspended,
    variant: "warning",
  },
];
const offer: StatusItem[] = [
  {
    id: OfferStatus.None,
    name: "status.offer.none",
    variant: "dark",
  },
  {
    id: OfferStatus.Active,
    name: "status.offer.active",
    variant: "success",
  },
  {
    id: OfferStatus.Accepted,
    name: "status.offer.accepted",
    variant: "success",
  },
  {
    id: OfferStatus.Rejected,
    name: "status.offer.rejected",
    variant: "danger",
  },
];
const incident: StatusItem[] = [
  {
    id: IncidentStatus.Active,
    name: "status.incident.active",
    variant: "success",
  },
  {
    id: IncidentStatus.Resolved,
    name: "status.incident.resolved",
    variant: "success",
  },
  {
    id: IncidentStatus.Cancel,
    name: "status.incident.canceled",
    variant: "danger",
  },
];
const incidentReason: (StatusItem & { status: IncidentStatus })[] = [
  {
    name: "status.incidentReason.notDefined",
    id: IncidentStatusReason.NotDefined,
    variant: "success",
    status: IncidentStatus.Active,
  },
  {
    name: "status.incidentReason.inProgress",
    id: IncidentStatusReason.InProgress,
    variant: "success",
    status: IncidentStatus.Active,
  },
  {
    name: "status.incidentReason.onHold",
    id: IncidentStatusReason.OnHold,
    variant: "success",
    status: IncidentStatus.Active,
  },
  {
    name: "status.incidentReason.waitingForDetails",
    id: IncidentStatusReason.WaitingForDetails,
    variant: "success",
    status: IncidentStatus.Active,
  },
  {
    name: "status.incidentReason.researching",
    id: IncidentStatusReason.Researching,
    variant: "success",
    status: IncidentStatus.Active,
  },
  {
    name: "status.incidentReason.new",
    id: IncidentStatusReason.New,
    variant: "success",
    status: IncidentStatus.Active,
  },
  {
    name: "status.incidentReason.processStarted",
    id: IncidentStatusReason.ProcessStarted,
    variant: "success",
    status: IncidentStatus.Active,
  },
  {
    name: "status.incidentReason.waitingForTechnicalInspection",
    id: IncidentStatusReason.WaitingForTechnicalInspection,
    variant: "success",
    status: IncidentStatus.Active,
  },
  {
    name: "status.incidentReason.manufactureServiceOrder",
    id: IncidentStatusReason.ManufactureServiceOrder,
    variant: "success",
    status: IncidentStatus.Active,
  },
  {
    name: "status.incidentReason.problemSolved",
    id: IncidentStatusReason.ProblemSolved,
    variant: "warning",
    status: IncidentStatus.Resolved,
  },
  {
    name: "status.incidentReason.informationProvided",
    id: IncidentStatusReason.InformationProvided,
    variant: "warning",
    status: IncidentStatus.Resolved,
  },
  {
    name: "status.incidentReason.cancelled",
    id: IncidentStatusReason.Cancelled,
    variant: "danger",
    status: IncidentStatus.Cancel,
  },
  {
    name: "status.incidentReason.merged",
    id: IncidentStatusReason.Merged,
    variant: "danger",
    status: IncidentStatus.Cancel,
  },
];
const incidentActivity = [
  {
    name: "status.incidentActivity.open",
    id: CRMActivityStatus.Open,
    variant: "success" as variantType,
  },
  {
    name: "status.incidentActivity.completed",
    id: CRMActivityStatus.Completed,
    variant: "success" as variantType,
  },
  {
    name: "status.incidentActivity.canceled",
    id: CRMActivityStatus.Canceled,
    variant: "danger" as variantType,
  },
];
const sales: StatusItem[] = [
  {
    id: SalesStatus.None,
    name: "status.sales.none",
    variant: "dark",
  },
  {
    id: SalesStatus.Backorder,
    name: "status.sales.backorder",
    variant: "warning",
  },
  {
    id: SalesStatus.Delivered,
    name: "status.sales.delivered",
    variant: "success",
  },
  {
    id: SalesStatus.Invoiced,
    name: "status.sales.invoiced",
    variant: "primary",
  },
  {
    id: SalesStatus.Canceled,
    name: "status.sales.canceled",
    variant: "danger",
  },
];
const purchase: StatusItem[] = [
  {
    id: PurchaseStatus.None,
    name: "status.purchase.none",
    variant: "dark",
  },
  {
    id: PurchaseStatus.Backorder,
    name: "status.purchase.backorder",
    variant: "warning",
  },
  {
    id: PurchaseStatus.Received,
    name: "status.purchase.received",
    variant: "success",
  },
  {
    id: PurchaseStatus.Invoiced,
    name: "status.purchase.invoiced",
    variant: "primary",
  },
  {
    id: PurchaseStatus.Canceled,
    name: "status.purchase.canceled",
    variant: "danger",
  },
];
const document: StatusItem[] = [
  {
    name: "status.document.none",
    id: DocumentStatus.None,
    variant: "dark",
  },
  {
    name: "status.document.purchaseOrder",
    id: DocumentStatus.PurchaseOrder,
    variant: "dark",
  },
  {
    name: "status.document.confirmation",
    id: DocumentStatus.Confirmation,
    variant: "dark",
  },
  {
    name: "status.document.pickingList",
    id: DocumentStatus.PickingList,
    variant: "dark",
  },
  {
    name: "status.document.pickingSlip",
    id: DocumentStatus.PickingSlip,
    variant: "dark",
  },
  {
    name: "status.document.invoiced",
    id: DocumentStatus.Invoiced,
    variant: "dark",
  },
];
const CRMActivity = incidentActivity;
const CRMTaskReason: (StatusItem & { status: CRMActivityStatus })[] = [
  {
    name: "status.CRMTaskReason.notStarted",
    id: CRMTaskStatusReason.NotStarted,
    variant: "dark" as variantType,
    status: CRMActivityStatus.Open,
  },
  {
    name: "status.CRMTaskReason.inProgress",
    id: CRMTaskStatusReason.InProgress,
    variant: "dark" as variantType,
    status: CRMActivityStatus.Open,
  },
  {
    name: "status.CRMTaskReason.waitingOnSomeoneElse",
    id: CRMTaskStatusReason.WaitingOnSomeoneElse,
    variant: "dark" as variantType,
    status: CRMActivityStatus.Open,
  },
  {
    name: "status.CRMTaskReason.deferred",
    id: CRMTaskStatusReason.Deferred,
    variant: "dark" as variantType,
    status: CRMActivityStatus.Open,
  },
  {
    name: "status.CRMTaskReason.completed",
    id: CRMTaskStatusReason.Completed,
    variant: "dark" as variantType,
    status: CRMActivityStatus.Completed,
  },
  {
    name: "status.CRMTaskReason.cancelled",
    id: CRMTaskStatusReason.Cancelled,
    variant: "dark" as variantType,
    status: CRMActivityStatus.Canceled,
  },
];
const availability = [
  {
    name: "status.availability.active",
    id: AvailabilityStatus.Active,
    variant: "success" as variantType,
  },
  {
    name: "status.availability.inactive",
    id: AvailabilityStatus.Inactive,
    variant: "danger" as variantType,
  },
  {
    name: "status.availability.salesOnly",
    id: AvailabilityStatus.SalesOnly,
    variant: "info" as variantType,
  },
  {
    name: "status.availability.blocked",
    id: AvailabilityStatus.Blocked,
    variant: "danger" as variantType,
  },
];
const sellOffProduct = [
  {
    name: "status.sellOffProduct.preparation",
    id: SellOffProductStatus.Preparation,
    variant: "primary" as variantType,
  },
  {
    name: "status.sellOffProduct.active",
    id: SellOffProductStatus.Active,
    variant: "success" as variantType,
  },
  {
    name: "status.sellOffProduct.sold",
    id: SellOffProductStatus.Sold,
    variant: "dark" as variantType,
  },
  // {
  //   name: "status.sellOffProduct.needPhaseChange",
  //   id: SellOffProductStatus.NeedPhaseChange,
  //   variant: "warning" as variantType,
  // },
  // {
  //   name: "status.sellOffProduct.needClose",
  //   id: SellOffProductStatus.NeedClose,
  //   variant: "danger" as variantType,
  // },
  {
    name: "status.sellOffProduct.closed",
    id: SellOffProductStatus.Closed,
    variant: "dark" as variantType,
  },
  // {
  //   name: "status.sellOffProduct.notSold",
  //   id: SellOffProductStatus.NotSold,
  //   variant: "secondary" as variantType,
  // },
];
const printRequest = [
  {
    name: "status.printRequest.onProcess",
    id: GeneratePdfRequestStatus.OnProcess,
    variant: "primary" as variantType,
  },
  {
    name: "status.printRequest.completed",
    id: GeneratePdfRequestStatus.Completed,
    variant: "success" as variantType,
  },
  {
    name: "status.printRequest.failed",
    id: GeneratePdfRequestStatus.Failed,
    variant: "danger" as variantType,
  },
];
const pickup = [
  {
    name: "status.pickup.none",
    id: PickupStatus.None,
    variant: "primary" as variantType,
    bgClasses: "bg-primary bg-opacity-10",
    colorClasses: "text-primary",
    icon: "InfoCircle" as iconNameType,
  },
  {
    name: "status.pickup.pickupRequested",
    id: PickupStatus.PickupRequested,
    variant: "primary" as variantType,
    bgClasses: "bg-primary bg-opacity-10",
    colorClasses: "text-primary",
    icon: "TickCircle" as iconNameType,
  },
  {
    name: "status.pickup.pickupRequestCanceled",
    id: PickupStatus.PickupRequestCanceled,
    variant: "danger" as variantType,
    bgClasses: "bg-danger bg-opacity-10",
    colorClasses: "text-danger",
    icon: "CloseCircle" as iconNameType,
  },
  {
    name: "status.pickup.pickedUp",
    id: PickupStatus.PickedUp,
    variant: "success" as variantType,
    bgClasses: "bg-success bg-opacity-10",
    colorClasses: "text-success",
    icon: "TickCircle" as iconNameType,
  },
];
const orderInternal = [
  { name: "Active", id: OrderInternalStatus.Active },
  { name: "Blocked", id: OrderInternalStatus.Blocked },
];
const status = {
  orderInternal,
  pickup,
  printRequest,
  customer,
  offer,
  sales,
  purchase,
  document,
  incident,
  incidentReason,
  company,
  incidentActivity,
  CRMActivity,
  CRMTaskReason,
  availability,
  sellOffProduct,
};
export default status;
