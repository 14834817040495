import { PulsFileType } from "../enums";
import { fileDataType } from "../types";
import useAxios from "./useAxios";

type FileType = Blob | string;
type UploadFileType = (
  file: FileType,
  metadata?: {
    [key: string]: string | null | undefined;
  }
) => Promise<fileDataType>;

export default function useUploadFile(fileType: PulsFileType) {
  const { axios, loading } = useAxios();
  const uploadFile: UploadFileType = (file, metadata) => {
    return new Promise((resolve, reject) => {
      const isFile = typeof file === "object";
      if (!isFile) return resolve({ url: file } as fileDataType);
      const url = `/assetservice/api/assets/${fileType}`;
      const body = new FormData();
      const config = { timeout: 10 * 60 * 1000 };
      body.set("formFile", file);
      if (metadata) {
        Object.keys(metadata).forEach((key) => {
          const value = metadata[key];
          !!value && body.set(key, value);
        });
      }
      axios
        .post(url, body, config)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  return [uploadFile, loading.post] as const;
}
