import { Fragment, useContext, useEffect, useState } from "react";
import { SearchProductContext } from ".";
import {
  Badge,
  Drawer,
  Icon,
  Image,
  List,
  Pagination,
  SearchBox,
  Status,
  Text,
} from "../../../../../components";
import { config } from "../../../../../constants";
import { AvailabilityStatus, PimProductType } from "../../../../../enums";
import { useAxios, useConverters } from "../../../../../hooks";
import { isActiveAuction } from "../../../../../methods";
import { PimProduct, WithPaging } from "../../../../../types";

export default function PimProducts() {
  const { axios, loading } = useAxios();
  const { convertAmount } = useConverters();
  const { setProductId, pimProductType } = useContext(SearchProductContext);
  const [pimProducts, setPimProducts] = useState<WithPaging<PimProduct.Item>>(
    {}
  );
  const [params, setParams] = useState<PimProduct.Params>({
    pageNumber: 1,
    pageSize: config.pageSize,
    pimProductType: pimProductType,
  });
  const isBundle = pimProductType === PimProductType.Bundle;
  const handleSelect = (e: PimProduct.Item) => {
    return () => {
      const isBlocked = e.availabilityStatus === AvailabilityStatus.Blocked;
      !isBlocked && setProductId(e.id);
    };
  };
  const getPimProducts = () => {
    const url = "/salesservice/api/salestoolspimproduct";
    const config = { params };
    axios.get(url, config).then(({ data }) => {
      setPimProducts(data);
    });
  };
  useEffect(getPimProducts, [params]);
  return (
    <Fragment>
      <Drawer.Header>
        <h6 className="text-dark text-base">
          <Text>
            {isBundle
              ? "drawerTitles.searchBundleProduct"
              : "drawerTitles.searchPimProduct"}
          </Text>
        </h6>
      </Drawer.Header>
      <Drawer.Header className="flex items-center gap-4 border-b-0">
        <SearchBox
          variant="gray"
          value={params.keyword}
          onSubmit={(keyword) =>
            setParams((p) => ({ ...p, keyword, pageNumber: 1 }))
          }
          className="w-auto flex-1"
        />
        {/* <Button light onClick={toggleFilter}>
          <Icon name="Filter" variant="Bold" size={20} />
        </Button> */}
      </Drawer.Header>
      <Drawer.Body>
        <List loading={loading.get}>
          {pimProducts.items?.map((e) => {
            const hasAuction = !!e.auctions?.filter(isActiveAuction)?.length;
            const programStatus =
              e.supplierProgramAvailabilityStatus ?? AvailabilityStatus.Active;
            const productStatus =
              e.availabilityStatus ?? AvailabilityStatus.Active;

            const isProgramBlocked =
              programStatus === AvailabilityStatus.Blocked;
            const isProductBlocked =
              productStatus === AvailabilityStatus.Blocked;

            const isProgramInactive =
              programStatus === AvailabilityStatus.Inactive;
            const isProductInactive =
              productStatus === AvailabilityStatus.Inactive;

            const isBlocked = isProgramBlocked || isProductBlocked;
            const isInactive = isProgramInactive || isProductInactive;

            const availabilityStatus = isBlocked
              ? AvailabilityStatus.Blocked
              : isInactive
              ? AvailabilityStatus.Inactive
              : productStatus;
            return (
              <List.Item
                key={e.id}
                disabled={isBlocked || isInactive}
                onClick={handleSelect(e)}
              >
                <div className="relative w-32 h-20 rounded overflow-hidden">
                  <Image
                    src={e.avatar?.thumbnailUrl}
                    alt={e.originalName}
                    className="size-full object-cover"
                    isDynamic
                  />
                  {hasAuction && (
                    <Icon.Wrapper className="absolute top-1.5 right-1.5 !size-6 flex-center bg-[#FFE6D0] bg-opacity-100 p-0">
                      <Icon
                        name="DiscountShape"
                        variant="Bold"
                        className="text-pim size-5"
                      />
                    </Icon.Wrapper>
                  )}
                </div>
                <div className="flex-1">
                  <h6 className="block truncate text-dark text-base text-justify">
                    {e.originalName}
                  </h6>
                  <span className="block truncate text-secondary text-sm">
                    <Text>global.articleNumber</Text>: #{e.articleNumber}
                  </span>
                  <span className="block truncate text-secondary text-base">
                    <Text>global.supplierArticleNumber</Text>: #
                    {e.supplierArticleNumber}
                  </span>
                </div>
                <div className="min-w-fit flex-1 flex items-center justify-between gap-2 px-4 self-stretch">
                  <Status.Availability id={availabilityStatus} />
                  {hasAuction && (
                    <Badge
                      variant="warning"
                      className="text-xs font-medium py-1.5 px-2"
                    >
                      <Text>products.list.actionBadge</Text>
                    </Badge>
                  )}
                  <Badge
                    variant="light"
                    className="bg-gray-50 text-dark bg-opacity-100"
                  >
                    {convertAmount(e.vk1Price?.amount) || "--"}
                  </Badge>
                </div>
                {/* <Button as="span" light>
                <Text>button.choose</Text>
              </Button> */}
              </List.Item>
            );
          })}
        </List>
      </Drawer.Body>
      <Drawer.Footer>
        <Pagination
          setActivePage={(pageNumber) =>
            setParams((p) => ({ ...p, pageNumber }))
          }
          pageNumber={params.pageNumber}
          totalItems={pimProducts.totalItems}
          totalPages={pimProducts.totalPages}
        />
      </Drawer.Footer>
    </Fragment>
  );
}
