import { createContext, useContext, useEffect, useState } from "react";
import { ProductDetailsContext } from "..";
import { Loading, Text, Wrapper } from "../../../../components";
import { useAxios } from "../../../../hooks";
import { Product } from "../../../../types";
import AvailabilityStatusRow from "./AvailabilityStatusRow";

type AvailabilityStatusList = Product.AvailabilityStatusItem[];
type Context = { productAvailabilityStatuses: AvailabilityStatusList };
export const AvailabilityStatusContext = createContext({} as Context);

export default function AvailabilityStatus() {
  const { product } = useContext(ProductDetailsContext);
  const { axios, loading } = useAxios();
  const [productAvailabilityStatuses, setProductAvailabilityStatuses] =
    useState<AvailabilityStatusList>([]);
  const [programAvailabilityStatuses, setProgramAvailabilityStatuses] =
    useState<AvailabilityStatusList>([]);
  const productId = product.id;
  const programId = product.supplier?.program?.supplierProgramId;
  // const [showAddMenu, toggleAddMenu, hideAddMenu] = useToggle(false);
  // const handleRemovedItem = (id: string) => {
  //   setData((p) => {
  //     const data = cloneDeep(p);
  //     const index = data.findIndex((e) => e.id === id);
  //     const has = index !== -1;
  //     if (has) data.splice(index, 1);
  //     return data;
  //   });
  // };
  const getProductAvailabilityStatuses = () => {
    if (!productId) return;
    const url = `/salesservice/api/salestoolspimproduct/${productId}/availability-status`;
    axios.get(url).then(({ data }) => {
      setProductAvailabilityStatuses(data ?? []);
    });
  };
  const getProgramAvailabilityStatuses = () => {
    if (!programId) return;
    const url = `/salesservice/api/supplierprograms/${programId}/availability-status`;
    axios.get(url).then(({ data }) => {
      setProgramAvailabilityStatuses(data ?? []);
    });
  };
  useEffect(getProductAvailabilityStatuses, [productId]);
  useEffect(getProgramAvailabilityStatuses, [programId]);
  return (
    <AvailabilityStatusContext.Provider value={{ productAvailabilityStatuses }}>
      <Wrapper>
        <Wrapper.Header className="border-b-0">
          <h6 className="text-sm text-dark font-medium">
            <Text>products.details.availabilityStatus.title</Text>
          </h6>
        </Wrapper.Header>
        <Wrapper.Body className="space-y-2.5">
          {loading.get ? (
            <Loading.AvailabilityStatus />
          ) : (
            productAvailabilityStatuses.map((e) => (
              <AvailabilityStatusRow
                key={e.id}
                availabilityStatus={e}
                // onSubmitted={getData}
                // onRemoved={handleRemovedItem}
              />
            ))
          )}
        </Wrapper.Body>
        {/* <Wrapper.Footer className="border-t-0">
          <Button type="button" variant="primary" light onClick={toggleAddMenu}>
            <Icon name="Add" className="size-5" />{" "}
            <Text>products.details.availabilityStatus.addButton</Text>
          </Button>
        </Wrapper.Footer> */}
      </Wrapper>
      <Wrapper>
        <Wrapper.Header className="border-b-0">
          <h6 className="text-sm text-dark font-medium">
            <Text>products.details.availabilityStatus.title</Text>
          </h6>
        </Wrapper.Header>
        <Wrapper.Body className="space-y-2.5">
          {loading.get ? (
            <Loading.AvailabilityStatus />
          ) : (
            programAvailabilityStatuses.map((e) => (
              <AvailabilityStatusRow
                key={e.id}
                availabilityStatus={e}
                // onSubmitted={getData}
                // onRemoved={handleRemovedItem}
              />
            ))
          )}
        </Wrapper.Body>
        {/* <Wrapper.Footer className="border-t-0">
          <Button type="button" variant="primary" light onClick={toggleAddMenu}>
            <Icon name="Add" className="size-5" />{" "}
            <Text>products.details.availabilityStatus.addButton</Text>
          </Button>
        </Wrapper.Footer> */}
      </Wrapper>
      {/* <AvailabilityStatusForm
        isOpen={showAddMenu}
        toggle={hideAddMenu}
        onSubmitted={getData}
      /> */}
    </AvailabilityStatusContext.Provider>
  );
}
