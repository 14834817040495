import commissions from "./commissions";
import company from "./companies";
import customers from "./customers";
import errorCodes from "./errorCodes";
import incident from "./incidents";
import pickup from "./pickup";
import products from "./products";
import sellOffProducts from "./sellOffProducts";
import suppliers from "./suppliers";
import task from "./task";

const en = {
  global: {
    noItems: "No item found",
    userNotFoundTitle: "User not found",
    userNotFoundText: "Try something else",
    pagination: "Showing {{ from }} to {{ to }} of {{ totalItems }} entries",
    filter: "Filter",
    customerInfo: "Customer info",
    addedAt: "Added at",
    createdAt: "Created at",
    createdByName: "Created by",
    lastUpdatedAt: "Last update at",
    lastUpdatedByName: "Last updated by",
    until: "Until",
    updatedAt: "Updated at",
    customerRegistrationAt: "Registration at",
    searchBox: "Search...",
    searchCalendarItem: "Search task title or customer names...",
    submit: "Please submit your changes",
    logout: "Logout",
    by: "By",
    version: "Version",
    signedByCustomer: "Signed by customer",
    orEnterManual: "Or enter manual",
    noAddress: "No address here",
    tomorrow: "Tomorrow",
    next3days: "Next 3 days",
    in7days: "in 7 days",
    thisWeek: "this week",
    thisMonth: "this month",
    last6Month: "last 6 month",
    selectStore: "Select your store",
    selectSaleChannel: "Select your sales channel",
    price: "Price",
    originalPrice: "Original price",
    finalPrice: "Final price",
    totalPrice: "Total Price",
    orderTaxFreeBadge: "Tax free",
    orderTotalUpFrontPayment: "Total Upfront payment",
    orderTotalUpFrontPaymentAmount:
      "Amount paid: <span class='text-base text-dark'>{{ amount }}</span>",
    productPrice: "Product price",
    totalLineItemPrice: "Total line item price",
    supplierPrice: "Supplier price",
    customerAddress: "Customer address",
    noPimProduct: "No item",
    addProductManually: "Add product manually",
    confirmDeleteDocument: "Are you sure you want delete this document?",
    pleaseWait: "Please wait ...",
    storeName: "Store",
    offerLogisticText: "Desire Delivery date",
    offerCompletedInfo: "Completed info",
    infoTitle: "Info",
    logisticsTitle: "Logistics",
    shippingMethodsText: "Available shipping methods",
    lineItemProductTitle: "Product title",
    articleNumber: "Article number",
    supplierName: "Supplier",
    supplierArticleNumber: "Supplier article number",
    supplierCode: "Code",
    programName: "Program",
    brandName: "Brand",
    lineItemNormalPrice: "Normal price",
    lineItemSalesPrice: "Sales price",
    lineItemProductInventoryTitle: "Availability ( Lager )",
    lineItemProductInventoryYes: "Yes",
    lineItemProductInventoryNo: "No",
    lineItemProductInventoryStock: "Stock",
    lineItemIsReserved: "Reserved",
    lineItemReservationNumber: "Reservation Number",
    lineItemProductInventoryReserve: "Reserve",
    lineItemProductInventoryUnreserve: "Cancel reservation",
    lineItemProductInventoryNotEnough: "Not enough",
    lineItemProductInventoryNoAvailable: "Inventory info not available",
    lineItemOptionalDiscountManualValue: "Manual value",
    lineItemOptionalDiscountDescription: "Description",
    offerTitle: "Offer",
    downPaymentAmount: "Amount",
    downPaymentDueDate: "Due date",
    downPaymentReason: "Reason",
    quantityTitle: "Quantity",
    lineItemDiscounts: "Discounts",
    orderTitle: "Order",
    orderDeadline: "Deadline",
    orderPendingCustomerConfirmation: "Pending confirmation",
    orderConfirmedCustomerConfirmation: "Confirmed by customer",
    desireDeliveryDateTitle: "Desire delivery date",
    lineItemsTitle: "Line Items",
    calendarWeek: "KW",
    lineItemCalendarWeekNotValid: "Not valid",
    calendarWeekText: "From {{ date1 }} to {{ date2 }}",
    offerDownPaymentIsNotSet: "Not set",
    companyIsNotApproved: "This company has not been approved yet!",
    orderVersion: "Version",
    orderChannelName: "Channel",
    orderLastPublishAt: "Published at",
    orderLastPublishDate: "Last publish date",
    orderLastPublishedVersion: "Last published version",
    orderLastPublishedBy: "Last published by",
    media: "Media",
    confirmDeleteAddress: "Are you sure you want delete this Address?",
    offerFinancingInfo: "Lorem ipsum dolor sit amet consectetur.",
    orderFinancingInfo: "Lorem ipsum dolor sit amet consectetur.",
    orderAssignedToCommissionGroup: "Assigned to",
    orderSalesPersonTitle: "Owner",
    orderSecondSalesPersonTitle: "Second sales person",
    orderSecondSalesPersonShareTitle: "Share",
    lineItemInfoTitle: "Info",
    lineItemPriceTitle: "Price",
    lineItemProductGroupTitle: "Product group",
    lineItemSalesPersonCommissionTitle: "Sales commission",
    lineItemStatusInfo: "Status",
    selectChannel: "Select channel",
    currentErpStatus: "Current status", // order/order line item details
    allFilterItem: "All",
    noPermissionPageTitle: "You do not have permission to view this page",
    noPermissionPageText: "Please try something else",
    noPermissionDashboardTitle: "You have no access to dashboard",
    noPermissionDashboardText: "Please login with another account",
  },
  status: {
    customer: {
      notRegistered: "Not registered",
      onboarding: "Onboarding",
      registered: "Registered",
    },
    company: {
      approved: "Approved",
      pending: "Pending",
      suspended: "Suspended",
    },
    offer: {
      none: "None",
      active: "Active",
      accepted: "Accepted",
      rejected: "Rejected",
    },
    order: {
      published: "Published",
      notPublished: "Not published",
    },
    incident: {
      active: "Active",
      resolved: "Resolved",
      canceled: "Canceled",
    },
    incidentReason: {
      notDefined: "Not defined",
      inProgress: "In progress",
      onHold: "On hold",
      waitingForDetails: "Waiting for details",
      researching: "Researching",
      new: "New",
      processStarted: "Process started",
      waitingForTechnicalInspection: "Waiting for technical inspection",
      manufactureServiceOrder: "Manufacture service order",
      problemSolved: "Problem solved",
      informationProvided: "Information provided",
      cancelled: "Cancelled",
      merged: "Merged",
    },
    incidentActivity: {
      open: "Active",
      completed: "Completed",
      canceled: "Canceled",
    },
    sales: {
      notPublished: "Not published",
      none: "Sent To ERP",
      backorder: "In Progress",
      delivered: "Sent out",
      invoiced: "Invoiced created",
      canceled: "Order Canceled",
    },
    purchase: {
      notPublished: "Not published",
      none: "Sent To ERP",
      backorder: "In Progress",
      received: "Sent In",
      invoiced: "Invoiced created",
      canceled: "Order Canceled",
    },
    document: {
      notPublished: "Not published",
      none: "Not Processed",
      purchaseOrder: "Open order",
      confirmation: "Order Confirmed",
      pickingList: "Picking List",
      pickingSlip: "Packing Slip",
      invoiced: "Invoiced",
    },
    CRMTaskReason: {
      notStarted: "Not Started",
      inProgress: "In Progress",
      waitingOnSomeoneElse: "Waiting On Someone Else",
      deferred: "Deferred",
      completed: "Completed",
      cancelled: "Cancelled",
    },
    availability: {
      active: "Active",
      inactive: "Inactive",
      salesOnly: "Sales only",
      blocked: "Blocked",
    },
    sellOffProduct: {
      preparation: "Preparation",
      active: "Active",
      sold: "Sold",
      needPhaseChange: "Need Phase Change",
      needClose: "Need Close",
      closed: "Closed",
      notSold: "Not Sold",
    },
    printRequest: {
      onProcess: "On process",
      completed: "Completed",
      failed: "Failed",
    },
    pickup: {
      none: "Available to pickup",
      pickupRequested: "Reserved",
      pickupRequestCanceled: "Progress canceled",
      pickedUp: "Picked up by customer",
    },
  },
  types: {
    customer: {
      company: "Company",
      person: "Individual",
    },
    gender: {
      unknown: "Unknown",
      male: "Male",
      female: "Female",
      diverse: "Diverse",
      notDefined: "Not Defined",
    },
    salutation: {
      unknown: "Unknown",
      company: "Company",
      family: "Family",
      mr: "Mr.",
      mrs: "Mrs.",
      notProvided: "Not Provided",
      divers: "Divers",
      mrAndMrs: "Mr And Mrs",
    },
    salutationTitle: {
      dr: "Dr.",
      prof: "Prof.",
      mag: "Mag.",
    },
    offerSortBy: {
      none: "None",
      creationDate: "Creation Date",
      lastUpdate: "Last Update",
      offerTitle: "Offer Title",
    },
    product: {
      pim: "Pim product",
      Iwofurn: "Iwofurn",
      programManual: "Manual",
      programConfigurable: "Configurable",
    },
    supplier: {
      tradingGoods: "Trading Goods",
      consumableGoods: "Consumable Goods",
    },
    program: {
      none: "None",
      manual: "Manual",
      configurable: "Configurable",
    },
    supplierContactPersonDepartment: {
      general: "General",
      sales: "Sales",
      logistics: "Logistics",
      marketing: "Marketing",
      support: "Support",
      other: "Other",
    },
    supplierShippingDurationUnit: {
      none: "None",
      workDay: "Work Day",
      calendarDay: "Calendar Day",
    },
    document: {
      template: "Template",
      personal: "Personal Document",
    },
    incidentPriority: {
      notDefined: "Not Defined",
      high: "Priority High",
      normal: "Priority Normal",
      low: "Priority Low",
    },
    incidentOrigin: {
      notDefined: "Not Defined",
      phone: "Phone",
      email: "Email",
      web: "Web",
    },
    incidentTopic: {
      notDefined: "Not Defined",
      generalRequest: "General request",
      productRequest: "Product request",
      deliveryDateAgreement: "Delivery date agreement",
      reserveVan: "Reserve a van",
      purchaseAgreementChange: "Purchase agreement change",
      customerComplaint: "customer complaint",
    },
    incidentActivity: {
      notDefined: "Not Defined",
      email: "Email",
      phoneCall: "Phone Call",
      appointment: "Appointment",
      task: "Task",
      post: "Post",
      note: "Note",
      incidentresolution: "Incidentresolution",
    },
    lineItem: {
      pimProduct: "PIM",
      iwofurnProduct: "Iwofurn",
      customProduct: "Catalogue",
      productService: "Service",
      bundle: "Bundle",
      pimBundle: "Pim bundle",
      pimBundleItem: "Pim bundle item",
      sellOffProduct: "Sell off product",
      sellOffBundle: "Sell off bundle",
      sellOffBundleItem: "Sell off bundle item",
    },
    taskPriority: {
      low: "Low",
      normal: "Normal",
      high: "High",
    },
    calculationValue: {
      percent: "Percent",
      amount: "Amount",
      multiply: "Multiply",
      pureProfit: "Pure Profit",
    },
    crmTarget: {
      incident: "Incident",
      lead: "Lead",
      account: "Company",
      contact: "Customer",
      order: "Order",
    },
    documentTags: {
      sendToCustomer: "Send To Customer",
      contract: "Contract",
      information: "Information",
    },
    pimProduct: {
      standard: "Single",
      bundle: "Bundle",
    },
    amountCalculation: {
      amount: "Amount",
      percent: "Percent",
    },
    sellOffProductTimelineAction: {
      none: "None",
      productCreated: "Product created",
      phaseStarted: "Phase started",
      phaseChanged: "Phase changed",
      productSold: "Product sold",
      phaseClosed: "Phase closed",
      soldProductWithdrew: "Sold product withdrew",
    },
    salePriceTemplateItemSteps: {
      system1: "System 1",
      system2: "System 2",
      automaticDiscounts: "Automatic Discounts",
      catalogCalculation: "Catalog Calculation",
      optionalDiscounts: "Optional Discounts",
      individualDiscounts: "Individual Discounts",
    },
    auctions: {
      hasAuction: "Auctions",
      noAuctions: "No auctions",
    },
  },
  rules: {
    required: "Field is required",
    email: "The email is invalid",
    postalCode: "Field must be 5 characters in length",
    integer: "Only integer are valid",
    phoneNumber: "The field must not start with 0",
    percent: "The percentage must be between 0 and 100",
    minmax: "value should be between {{ min }} and {{ max }}",
    quantity: "The value 0 is not valid.",
  },
  radioButton: {
    desireDeliveryDatePickDate: "Pick a date",
    desireDeliveryDateMarkWeek: "Mark a week",
    active: "Active",
    block: "Block",
    basketImportAsSingleItem: "Import as a single item",
    basketImportAsBundle: "Import as a bundle",
  },
  button: {
    filter: "Filter",
    action: "Action",
    addNew: "Add New",
    cancel: "Cancel",
    ok: "Ok",
    back: "Back",
    close: "Close",
    change: "Change",
    delete: "Delete",
    next: "Next",
    submit: "Submit",
    submitChanges: "Submit Changes",
    search: "Search",
    viewDetails: "View Details",
    choose: "Choose",
    submitCustomer: "Submit customer",
    deactivateAccount: "Deactivate Account",
    addOffer: "Add new Offer",
    saveAndCloseOffer: "Save and close",
    nextAndCompleteOffer: "Next and complete offer",
    rejectOffer: "Reject Offer",
    acceptOffer: "Accept Offer",
    cancelForNow: "Cancel for now",
    submitOffer: "Submit offer",
    setOfferDownPayment: "Set down payment",
    chooseFile: "Choose file",
    createDocument: "Create document",
    requireSigned: "Required to be signed",
    optionalSigned: "Signature is optional",
    addDocument: "Add document",
    resetFilter: "Reset",
    changeStore: "Change store",
    changeSaleChannel: "Change sales channel",
    selectStore: "Select store",
    selectSaleChannel: "Choose sales channel",
    clear: "Clear",
    mobileAccount: "Mobile account",
    haveNotMobileAccount: "Have not mobile account",
    clickToSign: "Click here to sign",
    showInfo: "Show info",
    addAttribute: "Add an attribute",
    addProduct: "Add Product",
    addLineItem: "Add line item",
    addAddress: "Add address",
    orderDownPaymentQrCode: "QR code",
    day: "Day",
    month: "Month",
    year: "Year",
    useCustomerAddress: "Use",
    productList: "Product list",
    calculate: "Calculate",
    importProducts: "Import products",
    submitIncident: "Submit incident",
    createNewIncident: "Create new",
    attachProductsIncident: "Attach selected products",
    viewOfferDetails: "View details",
    viewOrderDetails: "View details",
    backToBasketList: "Back to list",
    importBasket: "Import basket",
    showCalendar: "Show calendar",
    hideCalendar: "Hide calendar",
    submitAppointment: "Submit appointment",
    submitTodo: "Submit to do",
    checkOfferCompleted: "Check",
    editLineItem: "Edit",
    addBillingAddress: "Add address",
    addShippingAddress: "Add address",
    editBillingAddress: "Edit address",
    editShippingAddress: "Edit address",
    documentDownload: "Download",
    addLineItemDiscount: "Add new discount",
    applyLineItemDiscount: "Apply discount",
    setLineItemDesireDeliveryDate: "Set",
    setOfferDesireDeliveryDate: "Set desire delivery date",
    setShippingMethodToAllLineItems: "Set shipping method to all line items",
    lineItemDetails: "Details",
    addSubLineItem: "Add sub item",
    details: "Details",
    edit: "Edit",
    publishOrder: "Publish order",
    republishOrder: "Republish order",
    submitEditOrder: "Submit",
    allOrderLineItems: "All",
    activeOrderLineItems: "Active items",
    canceledOrderLineItems: "Canceled items",
    cancelOrderLineItem: "Cancel line item",
    submitCancelOrderLineItem: "Cancel line item",
    addBundleLineItem: "Add bundle",
    addBundleCatalogueLineItem: "Add bundle",
    addOrderSecondSalesPerson: "Add second sales person",
    cancelOrderSecondSalesPerson: "Cancel",
    submitOrderSecondSalesPerson: "Add second sales person",
    status: "Status",
    checkTaskTarget: "Check",
    allOrders: "All",
    useAuctions: "Use auctions",
  },
  tableTitles: {
    lineItemName: "Line item",
    lineItemSupplier: "Supplier",
    lineItemAction: "Action",
    lineItemUnitPrice: "Unit price",
    lineItemQuantity: "Quantity",
    lineItemPrice: "Line price",
    lineItemProgramCode: "Program Code",
    lineItemSupplierILN: "Supplier ILN",
    basketInternalTag: "Basket internal tag",
    basketTag: "Tag",
    basketCreatedAt: "Created at",
    basketSenderName: "Sender name",
    basketPrice: "Iwofurn basket price",
    lineItemBrand: "Brand",
  },
  drawerTitles: {
    filter: "Filter",
    createOffer: "Create Offer",
    editOffer: "Edit offer details",
    acceptOffer: "Accept offer",
    rejectOffer: " Reject offer",
    personalDocument: "Personal Document",
    documentTemplate: "Choose document template",
    shipTo: "Ship to",
    billTo: "Bill to",
    addProduct: "Add product",
    chooseProgram: "Choose program",
    chooseSupplier: "Choose supplier",
    editOrderInfo: "Edit order info",
    createNewIncident: "Create new incident",
    attachProductsIncident: "Attach products to incident form",
    settings: "Settings",
    languages: "Languages",
    importBasket: "Import products from IWOFURN",
    addNewAppointment: "Add new appointment",
    addNewTodo: "Add new to do",
    searchPimProduct: "Search pim products",
    searchBundleProduct: "Search bundle products",
    lineItem: "Line item",
    bundle: "Bundle",
    subLineItem: "Sub line item",
    editLineItem: "Edit line item",
    lineItemOptionalDiscount: "Add optional discounts",
    createNewBundle: "Create new bundle",
    createProductFromCatalogue: "Create product from Catalogue",
    publishOrder: "Publish order",
    republishOrder: "Republish order",
    lineItemDocument: "Line Item Document",
    editOrder: "Edit order",
    chooseCommissionGroup: "Choose commission group",
    addOrderSecondSalesPerson: "Add second sales person",
    orderStatus: "Order Status",
    erpSalesStatuses: "Sales status",
    erpSalesDocumentStatuses: "Sales document status",
    erpPurchaseStatuses: "Purchase status",
    erpPurchaseDocumentStatuses: "Purchase document status",
    searchSellOffProducts: "Search sell off products",
    orderUpFrontPayments: "Payment status",
  },
  modalTitles: {
    favoriteProductDetails: "Product details",
    rejectInfo: "Reject info",
    updateInfo: "Update info",
    downPayment: "Down payment",
    documentType: "Select document Type",
    signHere: "Sign here",
    mobileAccountInfo: "Mobile account info",
    deleteProduct: "Delete product",
    selectProductList: "Select Product list",
    shareQrCodeWithCustomer: "Share this QR code with customer",
    confirmDeleteDocument: "Delete Document",
    assignedOffer: "Assigned offer",
    assignedOrder: "Assigned order",
    acceptOfferInfo: "Publish info",
    chooseCalendarTaskType: "Choose task type",
    chooseCustomer: "Choose customer",
    offerCompleteInfo: "Offer info",
    addDeliveryDate: "Add delivery date",
    desireDeliveryDate: "Desire delivery date",
    cancelOrderLineItem: "Cancel line item",
    confirmDeleteAddress: "Delete Address",
    setShippingMethodToAllLineItems: "Set shipping method to all line items",
  },
  actionTitles: {
    editOffer: "Edit",
    sendEmailOffer: "Send offer as an email",
    rejectOffer: "Reject and delete offer",
    customerDetailsOffer: "Open customer details",
    // Offer document type
    personalDocument: "Upload Document",
    templateDocument: "Choose from Templates",
    // Offer line items
    configProduct: "Config a product",
    searchProduct: "Search products",
    importBasket: "Import IWOFURN basket",
    addBundleLineItem: "Add bundle",
    addBundleCatalogueLineItem: "Add bundle",
    createProductFromCatalogue: "Create product from Catalogue",
    editProduct: "Edit product",
    addSubProduct: "Add Sub-product",
    deleteProduct: "Delete product",
    searchBundleProduct: "Search bundle products",
    searchSellOffProduct: "Search sell off products",
    // Order Action
    editOrder: "Edit ino",
    assignedOffer: "Offer assigned",
    sendOrderEmail: "Send order as an email",
    // Add delivery date modal
    assignToCommissionGroup: "Assign to commission group",
    setManual: "Set manual",
    // Delivery date
    editDeliveryDate: "Edit delivery date",
    deleteDeliveryDate: "Delete delivery date",
    // Calendar
    addNewAppointment: "Appointment",
    addNewTodo: "To do",
    editTask: "Edit",
    deleteTask: "Delete",
  },
  actionDescriptions: {
    // Offer document type
    personalDocument: "If you need more info, please check it out",
    templateDocument: "search and import documents from templates",
    // Offer line items
    configProduct: "IWOFURN",
    searchProduct: "Import from pim",
    importBasket: "from IWOFURN",
    addBundleLineItem: "Bundle of products",
    addBundleCatalogueLineItem: "Bundle of products",
    createProductFromCatalogue: "Manually create a product from catalogue",
    searchBundleProduct: "Import bundle from pim",
    searchSellOffProduct: "Import from sell off products",
  },
  tabs: {
    // Add customer
    basicInfo: "Basic info",
    contactInfo: "Contact info",
    location: "Location",
    completed: "Completed",
    // Customer details
    overview: "Overview",
    communication: "Communication channels",
    offers: "Offers",
    orders: "Orders",
    favoritesProducts: "Favorites",
    customerIncidents: "Incidents",
    commissions: "Commissions",
    addresses: "Addresses",
    // Offer / Order details
    lineItems: "Line items",
    logistic: "Logistic",
    billing: "Billing",
    offerDocuments: "Offer documents",
    orderDocuments: "Order documents",
    notes: "Notes",
    orderIncidents: "Incidents",
    orderSalesInfo: "Sales info",
  },
  tabsDescription: {
    // Add customer
    basicInfo: "Name & ...",
    contactInfo: "Email & number",
    location: "Address & ...",
    completed: "Woah, we are here",
  },
  formControls: {
    firstName: "First name",
    lastName: "Last name",
    gender: "Gender",
    salutation: "Salutation",
    salutationTitle: "Title",
    birthDate: "Date of birth",
    email: "Email address",
    countryCode: "Country code",
    mobileNumber: "Contact mobile Number",
    phoneNumber: "Contact phone Number",
    searchAddress: "Search address",
    name: "Name in address",
    additionalInfo: "Additional info",
    street: "Street",
    number: "Number",
    postalCode: "Postal code",
    city: "City",
    state: "State",
    country: "Country",
    description: "Description",
    offerTitle: "Offer title",
    offerNote: "Offer note",
    offerCustomerNote: "Customer note",
    offerSupplierNote: "Supplier note",
    lineItemNote: "Line item note",
    lineItemCustomerNote: "Customer note",
    lineItemSupplierNote: "supplier note",
    CustomerFirstName: "Customer first name",
    CustomerLastName: "Customer last name",
    CustomerEmailAddress: "Customer email address",
    CustomerMobileNumber: "Customer number",
    CustomerPhoneNumber: "Customer phone number",
    reason: "Add a reason",
    paymentDueDate: "Payment due date",
    orderTitle: "Order title",
    orderNote: "Order note",
    orderEnableDeadline: "Customer need more time to confirm this order",
    orderDeadline: "Customer confirmation deadline",
    orderSecondSalesPerson: "Second sales person (Optional)",
    orderTaxFree: "Tax free",
    downPayment: "Down payment",
    downPaymentDueDate: "Due date (Optional)",
    downPaymentReason: "Reason",
    documentTitle: "Document title",
    note: "Note",
    file: "File",
    addedSince: "Added since",
    lineItemTitle: "Line item title",
    lineItemEan: "EAN",
    articleNumber: "Article number",
    price: "Price",
    quantity: "Quantity",
    supplierArticleNumber: "Supplier article number",
    deActiveCustomer: "Confirm Account Deactivation",
    incidentTitle: "Title",
    incidentPriority: "Priority",
    incidentOrigin: "Origin",
    incidentTopicDescription: "Topic description",
    incidentAttachmentProducts: "Attachment products",
    incidentCustomerMood: "Customer mood",
    incidentDeadline: "Deadline",
    incidentDescription: "Incident description",
    removeBasketAfterImport: "Remove basket after import",
    calendarTaskTime: "Time",
    calendarTaskSyncToUser: "Sync to user",
    calendarTaskTitle: "Title",
    calendarTaskDescription: "Description",
    supplier: "Supplier",
    program: "Program",
    priceFrom: "Price from",
    priceTo: "Price to",
    desireDeliveryDateWeek: "Week number",
    desireDeliveryDateYear: "Year",
    lineItemCatalogueProductTitle: "Product title",
    lineItemCatalogueTitle: "Line item title",
    lineItemCatalogueSupplierArticleNumber: "Supplier article number",
    lineItemCatalogueEAN: "Product EAN",
    lineItemCatalogueSalesPrice: "Sales Price / VK3",
    lineItemCatalogueQuantity: "Quantity",
    lineItemCatalogueDescription: "Description",
    lineItemConfigurationText: "Configuration text",
    lineItemBundleTitle: "Bundle title",
    lineItemBundleQuantity: "Quantity",
    lineItemBundleDescription: "Description",
    lineItemBundleCatalogueTitle: "Bundle title",
    lineItemBundleCatalogueQuantity: "Quantity",
    lineItemBundleCatalogueDescription: "Description",
    applyDesireDeliveryDateToAllLineItemCheckBox: "Apply to all line items",
    title: "Title",
    financing: "Financing",
    orderSecondSalesPersonShare: "Share",
    documentTags: "Tags",
    blockReason: "Block Reason",
    expireAt: "Expire at",
  },
  togglers: {
    requiredSign: "Required to be signed",
    erp: "ERP",
    signedByCustomer: "Signed by customer",
    calendarTaskAllDay: "All day",
  },
  confirms: {
    addOffer: "I checked my store and chosen correctly.",
    acceptOffer:
      "By confirming this part, the offer will be accepted by customer.are you sure about this?",
    rejectOffer:
      "By confirming this part, the offer will be rejected by customer. are you sure about this?",
    deleteProduct: "Are you sure about removing this product?",
    cancelOrderLineItem: "Are you sure about canceling this line item?",
  },
  placeholders: {
    searchAddressCountry: "Country",
    searchAddress: "Search address",
    firstName: "Type name",
    lastName: "Type last name",
    gender: "Select gender",
    salutation: "Select salutation",
    salutationTitle: "Select title",
    birthDate: "Select date of birth",
    email: "Type email",
    mobileNumber: "11 22 33 444",
    phoneNumber: "11 22 33 444",
    name: "Name",
    additionalInfo:
      "Ex: floor number or unit number or additional information.",
    street: "Street",
    number: "Number",
    postalCode: "Postal code",
    city: "City",
    state: "State",
    country: "Country",
    documentTitle: "Title",
    documentNote: "Add a note about this document",
    value: "Value",
    attributeName: "Attribute name",
    code: "Code",
    searchPimProduct: "Search Product name or article number",
    incidentTitle: "Enter incident title",
    incidentPriority: "Select",
    incidentOrigin: "Select",
    incidentTopic: "Select",
    incidentTopicDescription: "Add a note about this topic",
    incidentAttachmentProducts: "Select",
    incidentDeadline: "Deadline",
    incidentDescription: "Add a note about this incident",
    searchBaskets: "Search iwofurn basket ID or name",
    calendarTaskTime: "Select",
    title: "Title",
    searchOrderSecondSalesPerson: "Search sales person",
    select: "Select",
  },
  alertTitles: {
    // Customer details
    deactivateAccount: "You are deactivating this account",
    // Offer details
    supplierLineItemRequired: "No supplier sync.",
    supplierSubLineItemRequired: "No supplier sync.",
    programLineItemRequired: "No program sync.",
    programSubLineItemRequired: "No program sync.",
    addressRequired: "Required address",
    documentRequired: "Required documents",
    lineItemsRequired: "Required line items",
    paymentConditionRequired: "Required payment conditions",
    productSupplierNotSet: "Supplier not set",
    productProgramNotSet: "Program not set",
    offerRequiredDocument: "Required documents",
    offerRequiredDesiredDeliveryDate: "Desire Delivery date",
    offerRequiredAddress: "Required address",
    offerRequiredPaymentConditions: "Required payment conditions",
    offerDownPaymentEmpty:
      "You can not set down payment for this offer because the offer is empty.",
    downPaymentMoreThanTotalAmount: "Payment limit exceeded.",
    allOfferLineItemMustHaveShippingMethod: "Set line items shipping method",
    allOrderLineItemMustHaveShippingMethod: "Set line items shipping method",
    canceledOrderLineItem: "Canceled",
    orderStatus: "Order status",
    blockedOrder: "Blocked",
    orderExpireAt: "Expire at",
    allOfferLineItemMustHaveShippingDate: "Set line items shipping date",
  },
  alertDescriptions: {
    // Customer details
    deactivateAccount: "This action will inform the customer by email.",
    // Offer details
    supplierLineItemRequired:
      "There is some line item in this offer that has no supplier. please check it.",
    supplierSubLineItemRequired:
      "There is some sub line item in this offer that has no supplier. please check it.",
    programLineItemRequired:
      "There is some line item in this offer that has no program. please check it.",
    programSubLineItemRequired:
      "There is some sub line item in this offer that has no program. please check it.",
    addressRequired:
      "Billing and shipping addresses not set. please check address tab.",
    documentRequired:
      "There are documents that not signed by the customer. Please check sales documents tab.",
    lineItemsRequired: "There are no line items. Please check line items tab.",
    paymentConditionRequired:
      "Payment conditions not set. please check payment conditions tab.",
    downPaymentMoreThanTotalAmount:
      "The down payment cannot exceed the total amount. please adjust your payment.",
    selectStore: "You must choose your store.",
    selectSaleChannel: "You must choose your sales channel.",
    haveNotMobileAccount: "This user have not mobile app account!",
    customerRegistrationNotCompleted: "Registration not completed!",
    allOfferLineItemMustHaveShippingMethod:
      "All offer line item must have shipping method",
    allOrderLineItemMustHaveShippingMethod:
      "All order line item must have shipping method",
    canceledOrderLineItem: "This line item has been canceled before.",
    allOfferLineItemMustHaveShippingDate:
      "All order line item must have shipping date",
  },
  menu: {
    titles: {
      customers: "CUSTOMERS",
      account: "ACCOUNT",
    },
    items: {
      customers: "Customers",
      offers: "Offers",
      orders: "Orders",
      products: "Products",
      incidents: "Incidents",
      suppliers: "Suppliers",
      tasks: "Tasks",
      pickup: "Pickup",
      notifications: "Notifications",
      settings: "Settings",
      sellOffProducts: "Sell-off Products",
    },
  },
  toast: {
    success: {
      addCustomer: "Customer added successfully.",
      editCustomer: "Customer edited successfully.",
      removeCustomer: "Customer removed successfully.",
      createOffer: "Offer created successfully.",
      editOffer: "Offer edited successfully.",
      editOrder: "Order edited successfully.",
      acceptOffer: "Offer accepted successfully.",
      rejectOffer: "Offer rejected successfully.",
      sendOfferMail: "Email sended successfully.",
      sendOrderMail: "Email sended successfully.",
      copyCimNumber: "Cim number copied successfully.",
      copyOfferNumber: "Offer number copied successfully.",
      copyOrderNumber: "Order number copied successfully.",
      setDownPayment: "Down payment set up successfully.",
      addDocument: "Document created successfully.",
      editDocument: "Document edited successfully.",
      removeDocument: "Document removed successfully.",
      addSubLineItem: "Sub line item added successfully.",
      addLineItem: "Line item added successfully.",
      editSubLineItem: "Sub line item edited successfully.",
      editLineItem: "Line item edited successfully.",
      removeSubLineItem: "Sub line item removed successfully.",
      removeLineItem: "Line item removed successfully.",
      addIncident: "Incident added successfully.",
      importIwofurnProduct: "Product imported successfully.",
      importBasket: "Your Basket items has been imported.",
      offerDesiredDeliveryDate: "Desired delivery date updated successfully.",
      orderDesiredDeliveryDate: "Desired delivery date updated successfully.",
      lineItemDesiredDeliveryDate:
        "Desired delivery date updated successfully.",
      lineItemReserve: "Line item reserved successfully.",
      lineItemUnreserve: "Line item unreserved successfully.",
      publishOrder: "Order published successfully.",
      cancelSubLineItem: "Sub line item canceled successfully.",
      cancelLineItem: "Line item canceled successfully.",
      addCustomerAddress: "Customer address added successfully.",
      editCustomerAddress: "Customer address edited successfully.",
      removeCustomerAddress: "Customer address removed successfully.",
      setShippingMethodToAllLineItems: "Line items edited successfully.",
      orderStatus: "Order status changed successfully.",
      editSalesTemplate: "SalesTemplate edited successfully.",
    },
    warning: {
      suggestedAddress:
        "The address has changed. Please check before submitting.",
      informedOrderVersion:
        "Please be informed this version is not the last published version. Please publish the current version",
    },
    error: {
      favoriteProductNotExistsInCurrentChannel:
        "Product Not Exists In Current Channel",
    },
  },
  offerPaymentDetails: {
    text: "You can check payment details here",
    charges: "Charges",
    quantity: "Quantity",
    total: "Total",
    lineItems: "Line items",
    downPayment: "Down payment",
  },
  page: {
    customerList: {
      searchBox: "Search...",
    },
    addNewCustomer: {
      title: "New customer",
      conditions: {
        title: "Terms & Conditions",
        text: "Must be accepted by customer.",
        gdprAccepted: "DSGVO Akzeptiert Terms & conditions",
      },
    },
    customerDetails: {
      overview: {
        removeCustomer: {
          title: "Deactivate Account",
        },
      },
      communication: {
        byPhone: "Phone",
        byApplication: "In App-Benachrichtigung",
        bySocialMedia: "Whatsapp",
        byEmail: "Email",
        bySMS: "SMS",
        byPost: "Post",
        familyBonusApplied: "TEILNAHME FAMILIENBONUS",
        newsletterSubscribed: "News letter",
        bulkEmail: "Bulk email",
      },
      offers: {
        filter: {
          status: {
            label: "Status",
          },
          sortBy: {
            label: "Sort by",
          },
        },
      },
    },
    offers: {
      documents: {
        noDocumentTitle: "There is no Document",
        noDocumentText: "You can not add or delete any document",
      },
    },
    orders: {
      statusTitle: {
        sales: "Sales: {{ status }}",
        salesDocument: "Sales Doc: {{ status }}",
        purchase: "Purchase: {{ status }}",
        purchaseDocument: "Purchase Doc: {{ status }}",
      },
      filterMenu: {
        title: "Filters",
        resetButton: "Reset filter",
        addDateRange: "Add date range",
        fromDate: "From date",
        toDate: "To date",
        salesPerson: "Sales person",
        selectSalesPerson: "Select sales person",
        salesPersonDrawerTitle: "Choose sales person",
        salesPersonSearchBox: "Search name or ID",
        salesPersonChooseButton: "Choose",
        allItem: "All",
        processStatus: "Process status",
        salesStatus: "Sales status",
        salesDocumentStatus: "Sales document status",
        purchaseStatus: "Purchase status",
        purchaseDocumentStatus: "Purchase document status",
        cancelButton: "Cancel",
        submitFilterButton: "Submit filter",
      },
    },
    lineItems: {
      statusTitle: {
        sales: "Sales: {{ status }}",
        salesDocument: "Sales Doc: {{ status }}",
        purchase: "Purchase: {{ status }}",
        purchaseDocument: "Purchase Doc: {{ status }}",
      },
    },
    calendar: {
      noItemTitle: "This day is free",
      noItemDescription: "No item found",
    },
  },
  //  ##############################
  company,
  incident,
  products,
  commissions,
  customers,
  task,
  suppliers,
  errorCodes,
  sellOffProducts,
  pickup,
};
export default en;
