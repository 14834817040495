import { twMerge } from "tailwind-merge";
import { Icon } from "../../components";
import { classNameProps, Program } from "../../types";

type Props = {
  program: {
    title: string;
    code: string | null;
    pimProductGroup?: null | Program.PimProductGroup;
    brand?: null | Program.Brand;
  } | null;
} & classNameProps;

export default function ProgramCard({ program, className }: Props) {
  const hasCode = !!program?.code;
  if (!program) return null;
  return (
    <div className={twMerge("flex flex-center w-full gap-4", className)}>
      <Icon.Wrapper rounded>
        <Icon name="DocumentText" variant="Bold" />
      </Icon.Wrapper>
      <div className="flex-1">
        <h6 className="truncate">{program.title}</h6>
        {hasCode && (
          <span className="block truncate text-secondary">#{program.code}</span>
        )}
      </div>
    </div>
  );
}
