import { twMerge } from "tailwind-merge";
import { Icon } from "../../components";
import { classNameProps } from "../../types";

type Props = {
  brand: { name: string; code: string | null } | null;
} & classNameProps;

export default function BrandCard({ brand, className }: Props) {
  const hasCode = !!brand?.code;
  if (!brand) return null;
  return (
    <div className={twMerge("flex flex-center w-full gap-4", className)}>
      <Icon.Wrapper rounded>
        <Icon name="DocumentText" variant="Bold" />
      </Icon.Wrapper>
      <div className="flex-1">
        <h6 className="truncate">{brand.name}</h6>
        {hasCode && (
          <span className="block truncate text-secondary">#{brand.code}</span>
        )}
      </div>
    </div>
  );
}
