import { Dispatch, useContext } from "react";
import toast from "react-hot-toast";
import { ProductDetailsContext } from "..";
import { Button, Icon, Modal, Text } from "../../../../components";
import { dateFormats } from "../../../../constants";
import { useAxios, useConverters } from "../../../../hooks";
import { Product, togglePropsType } from "../../../../types";

type Props = togglePropsType & {
  printRequest: Product.GeneratePdfRequestItem;
  onRemoved: Dispatch<string>;
};

export default function RemoveConfirm({
  isOpen,
  toggle,
  printRequest,
  onRemoved,
}: Props) {
  const { product } = useContext(ProductDetailsContext);
  const { axios, loading } = useAxios();
  const { convertDate } = useConverters();
  const remove = () => {
    const url = `/salesservice/api/salestoolspimproduct/${product.id}/generate-pdf-requests/${printRequest.id}`;
    axios.delete(url).then(() => {
      const message = "products.details.removeConfirm.removeMessage";
      toast.success(message);
      onRemoved(printRequest.id);
    });
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Modal.Header>
        <h6 className="text-base text-dark font-medium">
          <Text title={printRequest.title ?? ""}>
            products.details.removeConfirm.title
          </Text>
        </h6>
      </Modal.Header>
      <Modal.Body className="space-y-8">
        <p className="text-secondary">
          <Text>products.details.removeConfirm.text</Text>
        </p>
        <div className="flex items-center text-start gap-4">
          <Icon.Wrapper>
            <Icon name="Printer" variant="Bold" size={28} />
          </Icon.Wrapper>
          <div className="flex-1 space-y-1">
            <h6 className="block text-sm text-dark">{printRequest.title}</h6>
            <p className="block text-sm text-secondary">
              {convertDate(printRequest.createdAt, dateFormats.fullDate)}
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="flex items-center justify-end gap-4">
        <Button light onClick={toggle}>
          <Text>products.details.removeConfirm.cancelButton</Text>
        </Button>
        <Button variant="danger" onClick={remove} loading={loading.delete}>
          <Text>products.details.removeConfirm.removeButton</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
