import { cloneDeep } from "lodash";
import { Dispatch, useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ProductDetailsContext } from ".";
import {
  Button,
  CheckBox,
  Drawer,
  Dropdown,
  Icon,
  Image,
  List,
  NoItems,
  Pagination,
  SearchBox,
  Text,
} from "../../../components";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "../../../components/_Dropdown";
import { config, languages } from "../../../constants";
import { useAxios } from "../../../hooks";
import { togglePropsType, WithPaging } from "../../../types";

type Props = {
  onSubmitted: Dispatch<string>;
} & togglePropsType;
type Params = {
  pageNumber: number;
  pageSize: number;
  keyword?: string | null;
  isGlobal?: boolean;
  isActive?: boolean;
  language?: string;
};
type Data = {
  id: string;
  title: string;
  isGlobal: boolean;
  isActive: boolean;
};

export default function PrintTemplateDrawer({
  isOpen,
  toggle,
  onSubmitted,
}: Props) {
  const initParams: Params = {
    pageNumber: 1,
    pageSize: config.pageSize,
    isActive: true,
    language: "de",
  };
  const { product } = useContext(ProductDetailsContext);
  const { axios, loading } = useAxios();
  const [data, setData] = useState<WithPaging<Data>>({});
  const [params, setParams] = useState<Params>(initParams);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const hasLoading = loading.get || loading.post;
  const hasSelectedIds = !!selectedIds.length;
  const activeLanguage = languages.find((e) => e.id === params.language);
  const resetData = () => {
    setSelectedIds([]);
    setParams(initParams);
  };
  const handleToggle = () => {
    if (loading.post) return;
    resetData();
    toggle();
  };
  const handleSelectId = (id: string) => {
    setSelectedIds((p) => {
      const ids = cloneDeep(p);
      const has = ids.includes(id);
      if (has) return ids.filter((e) => e !== id);
      ids.push(id);
      return ids;
    });
  };
  const handleSetParams = (key: keyof typeof params) => {
    return (val: any) => {
      if (key !== "pageNumber") {
        return setParams((p) => ({ ...p, [key]: val, pageNumber: 1 }));
      }
      setParams((p) => ({ ...p, [key]: val }));
    };
  };
  const getData = () => {
    if (!isOpen) return;
    const url = "/salesservice/api/productlabeltemplates";
    const config = { params };
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  const submit = () => {
    const url = `/salesservice/api/salestoolspimproduct/${product.id}/generate-pdf-requests`;
    const body = { productLabelTemplateIds: selectedIds };
    axios.post(url, body).then(({ data }) => {
      const message = "products.printTemplate.submitMessage";
      toast.success(message);
      resetData();
      toggle();
      onSubmitted(data);
    });
  };
  useEffect(getData, [params, isOpen]);
  return (
    <Drawer isOpen={isOpen} toggle={handleToggle}>
      <Drawer.Menu>
        <Drawer.Header className="text-start flex items-center gap-4">
          <h6 className="flex-1 text-base text-dark font-medium">
            <Text>products.printTemplate.title</Text>
          </h6>
          <Dropdown onSelect={handleSetParams("language")}>
            <DropdownToggle
              variant={null}
              className="flex flex-center bg-gray-50 border-gray-50 size-10 p-0"
            >
              {!!activeLanguage && (
                <Image
                  src={activeLanguage.icon}
                  alt={activeLanguage.name}
                  className="w-6"
                />
              )}
            </DropdownToggle>
            <DropdownMenu>
              {languages.map((e) => (
                <DropdownItem
                  key={e.name}
                  eventKey={e.id}
                  className="flex items-center gap-2"
                >
                  <Image src={e.icon} alt={e.name} className="w-6" />
                  <h6 className="flex-1 text-sm text-dark font-medium uppercase">
                    {e.name}
                  </h6>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </Drawer.Header>
        <Drawer.Header>
          <SearchBox onSubmit={handleSetParams("keyword")} variant="gray" />
        </Drawer.Header>
        <Drawer.Body>
          {loading.get ? (
            <List loading={loading.get} />
          ) : !data.items?.length ? (
            <NoItems />
          ) : (
            <List loading={loading.get}>
              {data.items?.map((e) => {
                const active = selectedIds.includes(e.id);
                return (
                  <List.Item key={e.id} onClick={() => handleSelectId(e.id)}>
                    <Icon.Wrapper>
                      <Icon name="Printer" variant="Bold" />
                    </Icon.Wrapper>
                    <h6 className="flex-1 text-base text-dark font-medium truncate">
                      {e.title}
                    </h6>
                    <CheckBox value={active} className="pointer-events-none" />
                  </List.Item>
                );
              })}
            </List>
          )}
        </Drawer.Body>
        <Drawer.Footer>
          <Pagination
            pageNumber={params?.pageNumber ?? 0}
            pageSize={params.pageSize}
            totalItems={data?.totalItems ?? 0}
            totalPages={data?.totalPages ?? 0}
            setActivePage={handleSetParams("pageNumber")}
          />
        </Drawer.Footer>
        <Drawer.Footer className="flex items-center justify-end gap-4">
          <Button
            type="button"
            variant="danger"
            onClick={handleToggle}
            disabled={hasLoading}
          >
            <Text>products.printTemplate.cancelButton</Text>
          </Button>
          <Button
            type="button"
            variant="primary"
            onClick={submit}
            disabled={hasLoading || !hasSelectedIds}
            loading={loading.post}
          >
            <Text>products.printTemplate.submitButton</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
