import { createContext, useMemo } from "react";
import { Drawer, Text } from "../../../../../components";
import { LineItemHeaderInfo } from "../../../../../containers/LineItems";
import { LineItem, togglePropsType } from "../../../../../types";
import ManualForm from "./ManualForm";

type CreateBundleSubLineItemProps = togglePropsType & {
  parentId: string | null;
  parent: LineItem.Item | null;
};
type CreateBundleSubLineItemContextType = {
  parentId: string | null;
  parent: LineItem.Item | null;
  supplier: LineItem.Item["supplier"];
  program: LineItem.Item["supplierProgram"];
  toggle: () => void;
};

export const CreateBundleSubLineItemContext = createContext(
  {} as CreateBundleSubLineItemContextType
);

export default function CreateBundleSubLineItem({
  isOpen,
  toggle,
  parentId,
  parent,
}: CreateBundleSubLineItemProps) {
  // const [supplier, setSupplier] = useState<Supplier.Item | null>(null);
  const supplier = parent?.supplier ?? null;
  // const [program, setProgram] = useState<Program.Item | null>(null);
  const program = parent?.supplierProgram ?? null;
  const hasSupplier = !!supplier;
  const ActiveStep = useMemo(() => {
    return ManualForm;
  }, []);
  const activeTitle = useMemo(() => {
    if (!supplier) return "drawerTitles.chooseSupplier";
    if (!program) return "drawerTitles.chooseProgram";
    return "drawerTitles.createProductFromCatalogue";
  }, [supplier, program]);
  const handleToggle = () => {
    toggle();
  };
  return (
    <Drawer isOpen={isOpen} toggle={handleToggle}>
      <Drawer.Menu>
        <Drawer.Header>
          <h6 className="text-base text-dark text-center">
            <Text>{activeTitle}</Text>
          </h6>
        </Drawer.Header>
        <LineItemHeaderInfo
          supplier={supplier}
          program={program ? { title: program.name, code: program.code } : null}
        />
        <CreateBundleSubLineItemContext.Provider
          value={{
            parentId,
            parent,
            supplier,
            program,
            toggle: handleToggle,
          }}
        >
          {isOpen && <ActiveStep />}
        </CreateBundleSubLineItemContext.Provider>
      </Drawer.Menu>
    </Drawer>
  );
}
