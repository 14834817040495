import { Fragment, useContext, useEffect, useState } from "react";
import { ProductDetailsContext } from "..";
import { Pagination, Table, Text, Wrapper } from "../../../../components";
import { config } from "../../../../constants";
import { useAxios } from "../../../../hooks";
import { Product, WithPaging } from "../../../../types";
import GeneratePdfRequestRow from "./GeneratePdfRequestRow";

type Params = Product.GeneratePdfRequestParams;
type Data = Product.GeneratePdfRequestItem;

export default function PrintHistory() {
  const { product } = useContext(ProductDetailsContext);
  const initParams: Params = {
    pageNumber: 1,
    pageSize: config.pageSize,
    id: product.id,
  };
  const { axios, loading } = useAxios();
  const [data, setData] = useState<WithPaging<Data>>({});
  const [params, setParams] = useState(initParams);
  const handleSetParams = (key: keyof typeof params) => {
    return (val: any) => {
      if (key !== "pageNumber") {
        return setParams((p) => ({ ...p, [key]: val, pageNumber: 1 }));
      }
      setParams((p) => ({ ...p, [key]: val }));
    };
  };
  const getData = () => {
    const url = `/salesservice/api/salestoolspimproduct/${product.id}/generate-pdf-requests`;
    const config = { params };
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, [params, product.id]);
  return (
    <Fragment>
      {/* <section className="flex items-center gap-4">
        <SearchBox className="w-auto basis-1/3" onSubmit={handleSetParams('channelCode')} />
      </section> */}
      <Wrapper>
        <Wrapper.Body>
          <Table.Wrapper>
            <Table>
              {!loading.get && (
                <thead>
                  <tr>
                    <td className="!ps-20">
                      <Text>products.details.printHistory.items</Text>
                    </td>
                    <td className="text-center">
                      <Text>products.details.printHistory.status</Text>
                    </td>
                    <td className="text-center">
                      <Text>products.details.printHistory.price</Text>
                    </td>
                    <td data-sticky className="!px-7">
                      <Text>products.details.printHistory.action</Text>
                    </td>
                  </tr>
                </thead>
              )}
              <Table.Body loading={loading.get}>
                {data.items?.map((e) => (
                  <GeneratePdfRequestRow
                    key={e.id}
                    printRequest={e}
                    onRemoved={getData}
                  />
                ))}
              </Table.Body>
            </Table>
          </Table.Wrapper>
        </Wrapper.Body>
        <Wrapper.Footer>
          <Pagination
            pageNumber={params?.pageNumber ?? 0}
            pageSize={params.pageSize}
            totalItems={data?.totalItems ?? 0}
            totalPages={data?.totalPages ?? 0}
            setActivePage={handleSetParams("pageNumber")}
          />
        </Wrapper.Footer>
      </Wrapper>
    </Fragment>
  );
}
