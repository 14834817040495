const pickup = {
  list: {
    title: "Abholanfrage",
    findOrderTitle: "Bestellung finden",
    findOrderSearch: "Bestellnummer, Kundenname, Kunden-ID suchen...",
    searchButton: "Suchen",
    publishedAt: "Veröffentlicht am",
    lastPublishedVersion: "Letzte veröffentlichte Version",
    detailsButton: "Details",
  },
  details: {
    selectItemsToPickupButton: "Artikel zur Abholung auswählen",
    submitRequestPickupButton: "Anfrage zur Abholung ausgewählter Artikel",
    cancelRequestPickupButton: "Abbrechen",
    lastUpdatedAt: "Letzes Update am",
    lastUpdatedByName: "Letztes Update von",
    version: "Version",
    channelName: "Kanal",
    totalPrice: "Komplettpreis",
    totalUpFrontPayment: "Anzahlungssumme",
    deadline: "Frist",
    // totalPrice: "Gesamtpreis",
    noteButton: "Notizen",
    lineItems: {
      lineItems: "Positionen",
      quantity: "Menge/Lieferung",
      unitPrice: "Stückpreis",
      lineItemPrice: "Positionspreis",
      action: "Aktion",
      totalPrice: "Gesamtpreis",
      vk2: "VK2",
      vk1: "VK1",
      requestToPickupButton: "Zur Abholung anfordern",
      cancelProcessButton: "Prozess abbrechen",
      resentRequestButton: "Anfrage erneut senden",
      pickedUpByCustomer: "Vom Kunden abgeholt",
      pickedUpAt: "Am {{ date }}",
      canceledAt: "Abgebrochen am {{ date }}",
      selectButton: "Auswählen",
      unSelectButton: "Auswahl aufheben",
    },
  },
  orderNote: {
    title: "Bestellnotizen",
    deliveryDate: "Lieferdatum",
    deliveryDateValue: "KW {{ kw }}",
    orderNote: "Bestellnotiz",
    customerNote: "Kundennotiz",
  },
  lineItemNote: {
    title: "Positionsnotizen",
    lineItemNote: "Positionsnotiz",
    customerNote: "Kundennotiz",
    supplierNote: "Lieferantennotiz",
  },
  pickupConfirm: {
    title: "Abholbestätigung",
    text: "Sind Sie sicher, dass der Kunde diese Position abholen möchte?",
    deliveryDateAlert: "Diese Position hat bereits ein gewünschtes Lieferdatum",
    cancelButton: "Abbrechen",
    submitButton: "Zur Abholung anfordern",
  },
  pickupInfo: {
    title: "Abholinformationen",
    date: "Abholdatum",
    by: "Abgeholt von",
    closeButton: "Schließen",
  },
  submitRequestToPickup: {
    title: "Abholanfrage senden",
    cancelButton: "Abbrechen",
    submitButton: "Abholung anfordern",
  },
};

export default pickup;
